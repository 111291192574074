body{
 
 
 
  font-family: 'Open Sans';
  color:aliceblue;
  font-size: 20px;
  width: 100%;
}

.App {
 
  background-color: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%) no-repeat;
  
 
}
#kol {
  background-color: darkkhaki;
  
}
img{
  border-radius: 30px;
}
span{
  color: black;
  font-size: xx-large;
}


#rada{
  background-color: deepskyblue;
}
.greenRow{
  background-color:darkgreen;
}
#sloup{
  background-color:coral!important;
}
.orangeCol{
  background-color: chartreuse;
}